<template>
    <div>
        <!-- title -->
        <div class="container-fluid bg-slider-contact-form px-0 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <h1 class="d-lg-block d-none text-white mp-rbk mp-font-weight-medium font-size-faq-header-title">Über uns</h1>
                                <h1 class="d-lg-none d-block text-white mp-rbk mp-font-weight-medium font-size-faq-header-title-mobile">Über uns</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->
        <!-- content -->
        <div class="container-fluid bg-lightblue">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 px-5 py-5">
                    <p>
                    In den letzten fünf bis zehn Jahren entwickelte sich der Trend zu einer bewussteren und gesünderen Lebensweise.<br>
                    Einerseits hinsichtlich des physischen Wohlbefindens, beeinflusst durch Ernährung und Bewegung und andererseits im Hinblick auf die geistige Gesundheit,<br>
                    das psychische Wohlbefinden und dessen Stabilität.<br>
                    Dieser Trend zeigt sich sowohl im geschäftlichen Kontext bei Unternehmer, die sich coachen lassen, um höhere Einnahmen zu erzielen<br>
                    und adäquat mit Kunden oder Geschäftspartnern zu kommunizieren.<br>
                    Als auch bei Privatpersonen, welche Beratung von außenstehenden Professionisten in Anspruch nehmen möchten.<br>
                    Die Entwicklung von mergeplate.com unterstützt das Wachstum in diesen Bereichen.<br>
                    Sodass es schon bald kein Trend mehr, sondern Normalität ist, schon im Kindheitsalter eine bewusste gesunde Lebensweise zu entwickeln.<br>
                    </p>
                    <p>
                    Die Qualität setzt sich zusammen aus Authentizität, Fachwissen und Sympathie.<br>
                    Ein Experte lässt sich nur dann als solches definieren, wenn ein gewisses Maß an Ausbildungen, damit verbundenes Fachwissen und darauf abgestimmte Fähigkeiten mitgebracht werden.<br>
                    Da die Gesundheit eines Menschen etwas sehr Persönliches ist, spielen Auftreten und Empathie dabei eine wichtige Rolle.
                    Um diese Qualität zu erhalten und den Kunden einen gewissen Schutz zu gewähren, ist die Registrierung als Expert gleichzeitig eine Art Bewerbung.<br>
                    Bei dieser werden Dokumente hochgeladen, um die Berechtigung und Befähigung für das angegebene Gewerbe bzw. die angebotenen Dienste und somit auch den Status „Experte“ zu rechtfertigen und zu belegen.
                    Ein Profil wird erst hochgeladen, wenn gültige Dokumente übermittelt wurden.<br>
                    Mit diesem Verifizierungsprozess versucht mergeplate.com eine missbräuchliche Verwendung der Plattform zu vermeiden.<br>
                    Eine Ausnahme sind in Ausbildung befindliche Anbieter, heranwachsende Experten. Oftmals benötigen sie Praxisstunden, um die Ausbildung zu absolvieren und können sich dafür auch auf mergeplate.com bewerben.
                    Diese sind gekennzeichnet mit der Abkürzung i. A. für „in Ausbildung“. Auch sie müssen ihren Status bestätigen, indem sie die notwendigen Dokumente hochladen.
                    </p>
                    <p>
                    Editorische Notiz zur Schreibweise männlich/weiblich<br>
                    Wir bitten um Verständnis, dass aus Gründen der besseren Lesbarkeit auf eine durchgängige Nennung der männlichen und weiblichen Bezeichnungen verzichtet wurde.
                    Selbstverständlich beziehen sich alle Texte in gleicher Weise auf Männer und Frauen.
                    </p>
                </div>
            </div>
        </div>
        <!-- /content -->
    </div>
</template>


<script>
    import {mixinBasics} from "../../mixins/basics";

    export default {
        name: "About",

        mixins: [
            mixinBasics
        ],

        metaInfo() {
            return {
                title: 'Über uns',
                meta: [
                    { name: 'Über uns | mergeplate.com' },
                    { title: 'Über uns | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    `Über uns | mergeplate.com`,
                    `Über uns - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit`,
                    this.$route.path
                ))
            };
        },

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>


<style scoped>

</style>
